import { useQuery } from '@apollo/client'
import { ALL_PARTNERS } from 'graphql/queries/partners'
import { ALL_PLANS } from 'graphql/queries/plans'
import jsonExport from 'jsonexport/dist'
import React from 'react'
import {
  ArrayField,
  Datagrid,
  DateField,
  downloadCSV,
  EmailField,
  Filter,
  List,
  NumberField,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin'

import ListActions from './ListActions'

const exporter = subscriptions => {
  const subscriptionsForExport = subscriptions.map(subscription => {
    const { ...subscriptionForExport } = subscription
    subscriptionForExport.id = subscription.id
    subscriptionForExport.plan = subscription.plan.boTitle
    subscriptionForExport.firstname = subscription.user.firstname
    subscriptionForExport.lastname = subscription.user.lastname
    subscriptionForExport.email = subscription.user.email
    subscriptionForExport.job = subscription.user.job
    subscriptionForExport.startDate = subscription.startDate
    subscriptionForExport.endDate = subscription.endDate
    subscriptionForExport.status = subscription.status
    subscriptionForExport.platform = subscription.platform
    subscriptionForExport.urlSource = subscription.urlSource
    subscriptionForExport.createdAt = subscription.createdAt
    subscriptionForExport.quantity = subscription.quantity
    subscriptionForExport.organization = subscription.user.pharmacy ? subscription.user.pharmacy.organization : null
    subscriptionForExport.coupon = subscription.coupon ? subscription.coupon.id : null
    subscriptionForExport.trialPeriodDays = subscription.trialPeriodDays

    return subscriptionForExport
  })
  jsonExport(subscriptionsForExport, {
    headers: ['id', 'plan', 'firstname', 'lastname', 'email', 'job', 'startDate', 'endDate', 'status', 'platform', 'urlSource', 'createdAt', 'quantity', 'organization', 'coupon', 'trialPeriodDays'],
  }, (err, csv) => {
    downloadCSV(csv, 'subscriptions')
  })
}

const SubscriptionFilter = props => {
  const allPartners = useQuery(ALL_PARTNERS)
  const partners = allPartners.data?.partners?.map(partner => ({ id: partner.name, name: partner.name })) ?? []

  const allPlans = useQuery(ALL_PLANS)
  const plans = allPlans.data?.allPlans?.map(plan => ({ id: plan.id, name: plan.boTitle })) ?? []

  return (
    <>
      <Filter { ...props }>
        <TextInput
          label="Rechercher"
          source="q"
          alwaysOn
        />
        <SelectInput
          choices={ [
            { id: 'incomplete', name: 'Incomplete' },
            { id: 'incomplete_expired', name: 'Incomplete expired' },
            { id: 'trialing', name: 'Trialing' },
            { id: 'active', name: 'Active' },
            { id: 'past_due', name: 'Past due' },
            { id: 'canceled', name: 'Canceled' },
            { id: 'unpaid', name: 'Unpaid' },
          ] }
          label="Status"
          source="status"
          alwaysOn
        />
        <SelectInput
          choices={ [
            { id: 'google', name: 'Google' },
            { id: 'apple', name: 'Apple' },
            { id: 'stripe', name: 'Stripe' },
          ] }
          label="Platform"
          source="platform"
          alwaysOn
        />
        <SelectInput
          choices={ plans }
          label="Plans"
          source="plan"
          alwaysOn
        />
        <SelectInput
          choices={ partners }
          label="Partenaires"
          source="partner"
          alwaysOn
        />
      </Filter>
    </>
  )
}

const ListSubscription = props => (
  <List
    { ...props }
    filters={ <SubscriptionFilter /> }
    actions={ <ListActions /> }
    exporter={ exporter }
  >
    <Datagrid rowClick="show" bulkActionButtons={ false }>
      <TextField
        label="Nom d'utilisateur"
        sortable={ false }
        source="user.username"
      />
      <TextField
        label="Nom"
        sortable={ false }
        source="user.lastname"
      />
      <TextField
        label="Prénom"
        sortable={ false }
        source="user.firstname"
      />
      <EmailField
        label="Email"
        sortable={ false }
        source="user.email"
      />
      <TextField
        label="Plan"
        sortable={ false }
        source="plan.boTitle"
      />
      <DateField
        label="Date début"
        locales="fr-FR"
        sortable={ false }
        source="startDate"
      />
      <DateField
        label="Date fin"
        locales="fr-FR"
        sortable={ false }
        source="endDate"
      />
      <TextField
        label="Status"
        sortable={ false }
        source="status"
      />
      <NumberField
        label="Quantité"
        sortable={ false }
        source="quantity"
      />
      <TextField
        label="Groupement inscription"
        sortable={ false }
        source="user.organization"
      />
      <TextField
        label="Groupement pharmacie"
        sortable={ false }
        source="user.pharmacy.organization"
      />
      <TextField
        label="Coupon"
        sortable={ false }
        source="coupon.id"
      />
      <TextField
        label="Plateforme"
        sortable={ false }
        source="platform"
      />
      <TextField
        label="Job"
        sortable={ false }
        source="user.job"
      />
      <ArrayField
        label="Membre(s)"
        sortable={ false }
        source="teamMembers"
      >
        <Datagrid bulkActionButtons={ false }>
          <TextField
            label="Nom d'utilisateur"
            source="username"
          />
        </Datagrid>
      </ArrayField>
      <TextField
        label="Url"
        sortable={ false }
        source="urlSource"
      />
      <DateField
        label="Dernière connexion"
        locales="fr-FR"
        sortable={ false }
        source="user.lastConnexion"
        showTime
      />
    </Datagrid>
  </List>
)

export default ListSubscription
