import CustomToolbar from 'components/toolbar'
import React from 'react'
import {
  BooleanField,
  DateField,
  Edit,
  Labeled,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'

const EditPlan = props => (
  <Edit { ...props }>
    <SimpleForm toolbar={ <CustomToolbar /> }>
      <TextInput
        label="ID du plan"
        source="id"
        validate={ required() }
        fullWidth
      />
      <TextInput
        label="Nom du plan - client"
        source="title"
        validate={ required() }
        fullWidth
      />
      <TextInput
        label="Nom du plan - back-office"
        source="boTitle"
        validate={ required() }
        fullWidth
      />
      <TextInput
        label="Description"
        source="description"
        multiline
      />
      <TextInput
        label="ID Apple"
        source="appleId"
        fullWidth
      />
      <TextInput
        label="ID Google"
        source="googleId"
        fullWidth
      />
      <NumberInput
        format={ amount => amount / 100 }
        label="Prix (en €)"
        parse={ amount => Math.round(amount * 100) }
        source="amount"
        validate={ required() }
        fullWidth
      />
      <SelectInput
        choices={ [
          { id: 'month', name: 'Mensuel' },
          { id: 'year', name: 'Annuel' },
        ] }
        label="Récurrence"
        source="interval"
        validate={ required() }
      />
      <Labeled>
        <DateField
          label="Date de création"
          locales="fr-FR"
          source="createdAt"
          showTime
        />
      </Labeled>
      <Labeled
        sx={{
          marginTop: 2,
        }}
      >
        <BooleanField
          label="Archivé"
          source="isArchived"
        />
      </Labeled>
    </SimpleForm>
  </Edit>
)

export default EditPlan
