import DescriptionIcon from '@mui/icons-material/Description'
import React from 'react'
import {
  ArrayField,
  Datagrid,
  DateField,
  EmailField,
  NumberField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin'

const CouponShow = props => (
  <Show { ...props }>
    <TabbedShowLayout>
      <Tab
        icon={ <DescriptionIcon /> }
        label="Résumé"
      >
        <TextField
          label="Identifiant du coupon"
          source="id"
        />
        <TextField
          label="Nom du coupon"
          source="name"
        />
        <NumberField
          label="Pourcentage de réduction"
          source="percentOff"
          emptyText="Aucun"
        />
        <NumberField
          label="Montant de réduction"
          source="amountOff"
          emptyText="Aucun"
        />
        <TextField
          label="Durée"
          source="duration"
        />
        <NumberField
          label="Combien de temps le coupon est repeté (en mois)"
          source="durationInMonth"
        />
        <NumberField
          label="Nombre d'utilisation maximum"
          source="maxRedemptions"
        />
        <TextField
          label="ID Stripe"
          source="stripeId"
        />
        <DateField
          label="Date de création"
          source="createdAt"
        />
        <ArrayField
          label="Abonnement(s)"
          source="subscriptions"
        >
          <Datagrid bulkActionButtons={ false }>
            <TextField
              label="Identifiant"
              source="id"
            />
            <TextField
              label="Prénom"
              source="user.firstname"
            />
            <TextField
              label="Nom"
              source="user.lastname"
            />
            <EmailField
              label="Email"
              source="user.email"
            />
            <TextField
              label="Plan"
              source="plan.boTitle"
            />
            <DateField
              label="Date de début"
              source="startDate"
            />
            <DateField
              label="Date de fin"
              source="endDate"
            />
            <TextField
              label="Statut"
              source="status"
            />
            <NumberField
              label="Quantité"
              source="quantity"
            />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default CouponShow
