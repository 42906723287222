import { gql } from '@apollo/client'

export const ALL_PLANS = gql`
  query {
    allPlans {
      id
      boTitle
    }
  }
`
