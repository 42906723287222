import { mapDataToShortProductInput } from 'data-mapper/product'
import { mapDataToShortTaxRateInput } from 'data-mapper/taxRate'

export const mapDataToPlanInput = plan => ({
  id: plan.id,
  title: plan.title,
  boTitle: plan.boTitle,
  description: plan.description,
  appleId: plan.appleId,
  googleId: plan.googleId,
  product: mapDataToShortProductInput(plan.product),
  taxRate: mapDataToShortTaxRateInput(plan.taxRate),
  amount: plan.amount,
  interval: plan.interval,
  isArchived: plan.isArchived,
})
