import DescriptionIcon from '@mui/icons-material/Description'
import React from 'react'
import {
  ArrayField,
  Datagrid,
  DateField,
  EmailField,
  NumberField,
  Show,
  ShowController,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin'

const SubscriptionShow = props => (
  <Show>
    <ShowController { ...props }>
      {controllerProps => (
        <TabbedShowLayout>
          <Tab icon={ <DescriptionIcon /> }
            label="Résumé"
          >
            <TextField
              label="Identifiant"
              source="id"
            />
            <TextField
              label="Plan"
              source="plan.boTitle"
            />
            <TextField
              label="Prénom"
              source="user.firstname"
            />
            <TextField
              label="Nom"
              source="user.lastname"
            />
            <EmailField
              label="Email"
              source="user.email"
            />
            <TextField
              label="Job"
              source="user.job"
            />
            <DateField
              label="Date de début"
              source="startDate"
            />
            <DateField
              label="Date de fin"
              source="endDate"
            />
            <TextField
              label="Status"
              source="status"
            />
            {
              controllerProps.record?.pastDueSince && (
                <TextField
                  label="Past Due depuis le"
                  source="pastDueSince"
                />
              )}
            <TextField
              label="Platform"
              source="platform"
            />
            <DateField
              label="Date de création"
              source="createdAt"
            />
            <NumberField
              label="Quantité"
              source="quantity"
            />
            {
              controllerProps?.record?.platform==='stripe' && (
                <TextField
                  label="ID Stripe"
                  source="stripeId"
                />
              )}
            <TextField
              label="Groupement"
              source="user.pharmacy.organization"
            />
            <TextField
              label="Coupon"
              source="coupon.name"
            />
            <NumberField
              label="Nombre de jours période d'essai"
              source="trialPeriodDays"
            />
            <ArrayField
              label="Membre(s)"
              source="teamMembers"
            >
              <Datagrid bulkActionButtons={ false }>
                <TextField
                  label="Nom"
                  source="lastname"
                />
                <TextField
                  label="Prénom"
                  source="firstname"
                />
                <EmailField
                  label="Email"
                  source="email"
                />
                <TextField
                  label="Nom d'utilisateur"
                  source="username"
                />
                <TextField
                  label="Job"
                  source="job"
                />
              </Datagrid>
            </ArrayField>
            <DateField
              label="Dernière connexion"
              locales="fr-FR"
              source="user.lastConnexion"
              showTime
            />
          </Tab>
        </TabbedShowLayout>
      )}
    </ShowController>
  </Show>
)

export default SubscriptionShow
