import { gql } from '@apollo/client'
import apolloClient from 'apolloClient'
import { mapGetListVariables } from 'data-mapper'
import { CREATE, GET_LIST, GET_ONE, UPDATE } from 'react-admin'

const getList = params => apolloClient
  .query({
    query: gql`
      query ($page: Int, $perPage: Int, $filter: SubscriptionFilter) {
        allSubscriptions(page: $page, perPage: $perPage, filter: $filter) {
              id
              user {
                id
                username
                firstname
                lastname
                email
                job
                organization
                lastConnexion
                pharmacy {
                    id
                    organization
                }
              }
              plan {
                id
                title
                boTitle
              }
              startDate
              endDate
              status
              platform
              createdAt
              coupon {
                id
                name
              }
              quantity
              trialPeriodDays
              urlSource
              teamMembers {
                  id
                  username
                  job
              }
            }
            _allSubscriptionsMeta(filter: $filter) {
                count
            }
      }
    `,
    variables: mapGetListVariables(params),
  })
  .then(({ data }) => ({
    data: data.allSubscriptions,
    total: data._allSubscriptionsMeta.count,
  }))

const getOne = params => apolloClient
  .query({
    query: gql`
      query ($id: ID!) {
        subscription(id: $id) {
          id
          user {
            id
            username
            firstname
            lastname
            email
            job
            lastConnexion
            pharmacy {
                id
                organization
            }
          }
          plan {
            id
            title
            boTitle
          }
          startDate
          endDate
          status
          pastDueSince
          platform
          createdAt
          coupon {
            id
            name
          }
          quantity
          trialPeriodDays
          stripeId
          urlSource
          teamMembers {
              id
              firstname
              lastname
              email
              username
              job
          }
        }
      }
    `,
    variables: {
      id: params.id,
    },
  })
  .then(({ data }) => ({
    data: data.subscription,
  }))

const create = params => apolloClient
  .mutate({
    mutation: gql`
      mutation ($user: ID!, $plan: ID!, $coupon: ID, $trialPeriodDays: Int, $quantity: Int) {
        createSubscription(
          user: $user,
          plan: $plan,
          coupon: $coupon,
          trialPeriodDays: $trialPeriodDays,
          quantity: $quantity
        ) {
          id
        }
      }
    `,
    variables: {
      user: params.data.user.id,
      plan: params.data.plan.id,
      coupon: params.data.coupon ? params.data.coupon.id : null,
      trialPeriodDays: params.data.trialPeriodDays ?? null,
      quantity: params.data.quantity ?? null,
    },
  })
  .then(({ data }) => ({
    data: data.createSubscription,
  }))

const update = params => apolloClient
  .mutate({
    mutation: gql`
      mutation ($id: ID!, $coupon: String, $urlSource: String) {
        updateSubscription(id: $id, coupon: $coupon, urlSource: $urlSource) {
          id
        }
      }
    `,
    variables: {
      id: params.id,
      coupon: params.data.coupon ? params.data.coupon.id : null,
      urlSource: params.data.urlSource,
    },
  })
  .then(({ data }) => ({
    data: data.updateSubscription,
  }))

export default (type, params) => {
  switch (type) {
    case GET_LIST: return getList(params)
    case GET_ONE: return getOne(params)
    case CREATE: return create(params)
    case UPDATE: return update(params)
    default: return new Promise()
  }
}
